// .para {
//   text-align: left;
// }

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Creepster&family=Merriweather:wght@400;700;900&family=Open+Sans&family=PT+Serif:wght@700&family=Playfair+Display:ital@1&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
}
// html {
//   height: 100%;
//   width: 100%;
// }

// body {
//   display: flex;
//   flex-direction: column;
//   min-height: 100%;
// }
html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
