#modalBackground,
.modalBackground {
  width: 30em;
  height: 30em;
  position: relative;
  display: block;
  cursor: pointer;
}

.closeButton {
  height: 4em;
  width: 4em;
  text-decoration: none;
  color: grey;
  background: none;
  border: none;
  position: absolute;
  top: 4%;
  left: 4%;
  z-index: 50;
}

.modalImage {
  position: relative;
  width: 35em;
  height: 35em;
  border-radius: 0.5em;
}

.modal {
  position: absolute;
  width: 60em;
  top: 20%;
  left: 22%;
}

.modalContainer {
  background-color: none;
}

@media screen and (max-width: 425px) {
  .modalImage {
    position: relative;
    width: 18em;
    height: 18em;
    border-radius: 0.5em;
  }
}

@media only screen and (min-width: 426px) and (max-width: 480px) {
  .modalImage {
    position: relative;
    width: 18em;
    height: 18em;
    border-radius: 0.5em;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .modalImage {
    position: relative;
    width: 18em;
    height: 18em;
    border-radius: 0.5em;
  }
}