

.mainpage {
  background-color: #7b9eba;
  width: 100%;
  height: 100vh;
}

.comeinscroll {
  display: block;
  text-align: center;
  padding-top: 1em;
  font-size: 2.8em;
}
.landingtitle {
  color: white;
  font-size: 3.5em;
  text-align: center;
  margin-top: 5em;
}

.abouttext {
  color: white;
  font-size: 2.5em;
  text-align: center;
}

.titlepagetop {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 5em;
}

.landingfooterflex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-right: 10em;
  width: 100%;
  height: 40vh;
  padding-bottom: 5em;
}

.comeonin a {
  color: white;
  font-size: 2em;
  -webkit-text-fill-color: #7b9eba;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fafafa;
}

.comeonin a:hover {
  -webkit-text-fill-color: #f6d7ba;
  -webkit-text-stroke-width: 0px;
}

.planepile {
  height: 30em;
  width: 30em;
  padding-bottom: 5em;
}

.flyingplane {
  display: none;
}
.scroll {
  display: block;
}

.typical {
  display: none;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}
@media only screen and (max-width: 425px) {
  .typical {
    display: none;
  }
  .scroll {
    display: block;
  }
  .mainflex {
    margin-left: 0em;
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-top: 2.5em;
  }
  .titlepagetop {
    display: none;
  }
  .comeonin {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .comeinscroll {
    display: block;
    color: white;
    font-size: 1.4em;
    padding-top: 0.5em;
    -webkit-text-fill-color: #f6d7ba;
    -webkit-text-stroke-width: 0px;
    text-align: center;
  }
  .landingtitle {
    color: white;
    font-size: 2em;
    text-align: center;
  }
  .mainpage {
    background-color: #7b9eba;
    width: 100%;
    height: 100vh;
  }
  .abouttext {
    padding: 0;
    font-size: 1.2em;
    text-align: center;
  }
  .landingfooterflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-top: 0em;
    margin-bottom: 0em;
  }

  .planepile {
    height: 18em;
    width: 18em;
    padding-bottom: 0em;
  }

  .flyingplane {
    display: none;
  }
  .titlepagetop {
    display: none;
  }
}
@media only screen and (min-width: 426px) and (max-width: 480px) {
  .typical {
    display: none;
  }
  .scroll {
    display: block;
  }
  .mainflex {
    margin-left: 0em;
  }

  .abouttext {
    padding: 0;
    font-size: 1.3em;
    text-align: center;
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-top: 2.5em;
  }
  .titlepagetop {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 6em;
    width: 100%;
  }
  .comeonin {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .mainpage {
    background-color: #7b9eba;
    width: 100%;
    height: 100vh;
  }

  .titlepagetop {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 8em;
    width: 100%;
  }

  .comeinscroll {
    display: block;
    color: white;
    font-size: 1.4em;
    -webkit-text-fill-color: #f6d7ba;
    -webkit-text-stroke-width: 0px;
    text-align: center;
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }

  .landingfooterflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .planepile {
    height: 18em;
    width: 18em;
    padding-bottom: 0em;
  }

  .landingtitle {
    color: white;
    font-size: 2em;
    text-align: center;
  }
  .mainflex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 100%;
  }
  .flyingplane {
    height: 10em;
    width: 10em;
  }
  .titlepagetop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-right: 20em;
    padding-bottom: 2em;
  }
}

// .flyingplane {
//   height: 14em;
//   width: 14em;
//   // padding-left: 5em;
// }

.titlepagetop {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  // margin-left: 8em;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-top: 2.5em;
}
.abouttext {
  padding-top: 0.7em;
}

.landingtitle {
  padding-bottom: 0.5em;
}
.comeonin a {
  color: #f6d7ba;
  -webkit-text-fill-color: #f6d7ba;
  -webkit-text-stroke-width: 0px;
  font-size: 1em;
  padding-top: 1em;
  text-align: center;
}

.mainpage {
  display: flex;
  flex-direction: column;
  align-items: space-between;
}

.titlepagetop {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.scroll {
  width: 60px;
  height: 60px;
  border: 2px solid #f6d7ba;
  border-radius: 50%;
  position: relative;
  animation: down 1.5s infinite;
  -webkit-animation: down 1.5s infinite;
  &::before {
    content: "";
    position: absolute;
    top: 15px;
    left: 18px;
    width: 18px;
    height: 18px;
    border-left: 2px solid #f6d7ba;
    border-bottom: 2px solid #f6d7ba;
    transform: rotate(-45deg);
  }
}
.titleflex {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1em;
  line-height: 0.5%;
}

.planepile {
  padding-bottom: 0em;
  margin-left: 11em;
}
@keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(15px);
  }
  40% {
    transform: translate(0);
  }
}

@-webkit-keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(15px);
  }
  40% {
    transform: translate(0);
  }
}

@keyframes scroll {
  0% {
    height: 40px;
  }
  30% {
    height: 70px;
  }
  60% {
    height: 40px;
  }
}

@-webkit-keyframes scroll {
  0% {
    height: 40px;
  }
  30% {
    height: 70px;
  }
  60% {
    height: 40px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .typical {
    display: none;
  }
  .scroll {
    display: block;
  }
  .mainflex {
    margin-left: 0em;
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-top: 2.5em;
  }

  .landingfooterflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-top: 10em;
  }
  .planepile {
    height: 20em;
    width: 20em;
    padding-bottom: 0em;
  }
  .comeonin {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .comeinscroll {
    display: block;
    color: white;
    font-size: 1.4em;
    padding-top: 0.5em;
    -webkit-text-fill-color: #f6d7ba;
    -webkit-text-stroke-width: 0px;
    text-align: center;
  }
  .landingtitle {
    color: white;
    font-size: 2em;
    text-align: center;
  }
  .mainpage {
    background-color: #7b9eba;
    width: 100%;
    height: 100vh;
  }
  .abouttext {
    padding-top: 0.5;
    font-size: 1.3em;
    text-align: center;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .planepile {
    padding-top: 10em;
    height: 20em;
    width: 25em;
  }
  .comeonin {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .comeinscroll {
    display: block;
    color: white;
    font-size: 1.8em;
    padding-top: 0.5em;
    -webkit-text-fill-color: #f6d7ba;
    -webkit-text-stroke-width: 0px;
    text-align: center;
  }
  .landingtitle {
    color: white;
    font-size: 3em;
    text-align: center;
  }
  .abouttext {
    padding: 0;
    font-size: 1.8em;
    padding-top: 0.7em;
    text-align: center;
  }
}
