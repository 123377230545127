.projectTitle {
  color: white;
  width: 15em;
  height: 15em;
  border-radius: 10px;
  padding: 1.5em;
  justify-content: center;
}

h2 {
  color: white;
  font-size: 1.5em;
  text-align: center;
  border: solid 2px;
  text-align: center;
  padding: 0.5em;
}

h2:hover {
  color: orange;
}

.h2flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

h3 {
  color: white;
  font-size: 0.9em;
  text-align: center;
  padding-top: 2em;
}

button {
  display: inline-block;
  border: none;
  background-color: none;
  color: white;
}

.linkFlexProjectCode {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.projectLinks {
  color: white;
  font-size: 2em;
  background-color: none;
  border: none;
  text-decoration: none;
  text-align: center;
}

.projectLinkProject {
  font-size: 0.4em;
  border: solid 2px white;
  background-color: white;
  margin-right: 1em;
}

.projectLinkCode {
  font-size: 0.4em;
  border: solid 2px white;
  background-color: white;
}
@media only screen and (min-width: 319px) and (max-width: 374px) {
  .projectTitle {
    width: 6em;
    height: 6em;
    border-radius: 10px;
    padding: 0.5em;
    justify-content: center;
  }

  h2 {
    color: white;
    font-size: 0.7em;
    text-align: center;
    border: solid 2px;
    text-align: center;
    padding: 0.2em;
  }
  h3 {
    display: none;
  }
  .projectLinkProject {
    font-size: 0.5em;
    border: solid 2px white;
    background-color: white;
    margin-right: 1em;
  }

  .projectLinkCode {
    font-size: 0.5em;
    border: solid 2px white;
    background-color: white;
  }
  .projectLinks {
    color: white;
    font-size: 0.7em;
    background-color: none;
    border: none;
    text-decoration: none;
    text-align: center;
    margin-left: 0.6em;
    padding-top: 0.2em;
  }
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
  .projectTitle {
    width: 8em;
    height: 8em;
    border-radius: 10px;
    padding: 0.9em;
    justify-content: center;
  }

  h2 {
    color: white;
    font-size: 1em;
    text-align: center;
    border: solid 2px;
    text-align: center;
    padding: 0.1em;
  }
  h3 {
    display: none;
  }
  .projectLinkProject {
    font-size: 0.6em;
    border: solid 2px white;
    background-color: white;
    margin-right: 1em;
  }

  .projectLinkCode {
    font-size: 0.6em;
    border: solid 2px white;
    background-color: white;
  }
  .projectLinks {
    color: white;
    font-size: 1em;
    background-color: none;
    border: none;
    text-decoration: none;
    text-align: center;
    margin-left: 0.5em;
    padding-top: 0.5em;
  }
}
@media only screen and (min-width: 426px) and (max-width: 480px) {
  .projectTitle {
    width: 9em;
    height: 9em;
    border-radius: 10px;
    padding: 0.9em;
    justify-content: center;
    margin-bottom: 1em;
  }

  h2 {
    color: white;
    font-size: 1em;
    text-align: center;
    border: solid 2px;
    text-align: center;
    padding: 0.5em;
  }
  h3 {
    display: none;
  }
  .projectLinkProject {
    font-size: 0.8em;
    border: solid 2px white;
    background-color: white;
    margin-right: 1em;
  }

  .projectLinkCode {
    font-size: 0.8em;
    border: solid 2px white;
    background-color: white;
  }
  .projectLinks {
    color: white;
    font-size: 1em;
    background-color: none;
    border: none;
    text-decoration: none;
    text-align: center;
    margin-left: 1em;
    padding-top: 1em;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .projectTitle {
    width: 10em;
    height: 10em;
    border-radius: 10px;
    padding: 0.9em;
    justify-content: center;
  }

  h2 {
    color: white;
    font-size: 1em;
    text-align: center;
    border: solid 2px;
    text-align: center;
    padding: 0.5em;
  }
  h3 {
    display: none;
  }
  .projectLinkProject {
    font-size: 0.6em;
    border: solid 2px white;
    background-color: white;
    margin-right: 1em;
  }

  .projectLinkCode {
    font-size: 0.6em;
    border: solid 2px white;
    background-color: white;
  }
  .projectLinks {
    color: white;
    font-size: 1.2em;
    background-color: none;
    border: none;
    text-decoration: none;
    text-align: center;
    margin-left: 0.5em;
    padding-top: 0.5em;
  }

  .linkFlexProjectCode {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 1em;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .projectTitle {
    width: 11em;
    height: 11em;
    border-radius: 10px;
    padding: 0.9em;
    justify-content: center;
  }

  h2 {
    color: white;
    font-size: 1.2em;
    text-align: center;
    border: solid 2px;
    text-align: center;
    padding: 0.5em;
  }
  h3 {
    color: white;
    font-size: 0.8em;
    text-align: center;
    padding-top: 0.5em;
  }
  .projectLinkProject {
    font-size: 0.7em;
    border: solid 2px white;
    background-color: white;
    margin-right: 1em;
  }

  .projectLinkCode {
    font-size: 0.7em;
    border: solid 2px white;
    background-color: white;
  }
  .projectLinks {
    color: white;
    font-size: 1em;
    background-color: none;
    border: none;
    text-decoration: none;
    text-align: center;
    margin-left: 0.5em;
    padding-top: 0.5em;
  }
}
