.content {
  display: grid;
  grid-template-columns: repeat(auto-fill, 16em);
  grid-column-gap: 0.1em;
  grid-row-gap: 1.2em;
  justify-content: center;
  flex-wrap: wrap;
  margin: 3em;
  margin-left: 18em;
  margin-right: 18em;
}

@media only screen and (min-width: 319px) and (max-width: 374px) {
  .content {
    display: grid;
    grid-template-columns: repeat(auto-fill, 6em);
    grid-column-gap: 0.8em;
    grid-row-gap: 0.8em;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1em;
    margin-left: 1em;
    margin-right: 1em;
  }
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  .content {
    display: grid;
    grid-template-columns: repeat(auto-fill, 8em);
    grid-column-gap: 0.8em;
    grid-row-gap: 0.8em;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1em;
    margin-left: 1em;
    margin-right: 1em;
  }
}

@media only screen and (min-width: 426px) and (max-width: 480px) {
  .content {
    display: grid;
    grid-template-columns: repeat(auto-fill, 9em);
    grid-column-gap: 0.6em;
    grid-row-gap: 0.6em;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0.5em;
    margin-left: 1em;
    margin-right: 1em;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .content {
    display: grid;
    grid-template-columns: repeat(auto-fill, 10em);
    grid-column-gap: 2em;
    grid-row-gap: 1em;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0.5em;
    margin-left: 4em;
    margin-right: 4em;
    margin-top: 3em;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .content {
    display: grid;
    grid-template-columns: repeat(auto-fill, 11em);
    grid-column-gap: 0.7em;
    grid-row-gap: 0.7em;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0.5em;
    margin-left: 0em;
    margin-right: 0em;
    margin-top: 5em;
  }
}
