.skillspage {
  min-height: 100vh;
  background-color: white;
  width: 100vw;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237b9eba' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}
.skillstitle {
  color: #f6d7ba;
  font-size: 4em;
  margin-left: 2em;
  margin-top: 1em;
  font-family: "Merriweather", serif;
  width: 10em;
  margin-left: 2em;
}
.skillslisttwo {
  margin-right: 2.4em;
}
.skillslist {
  margin-left: 2.4em;
}

.navigationpinflex {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25em;
}

.skillsboxflexrow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80vw;
  height: 80vh;
  margin-top: 4em;
}
.skillsboxflex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.skillstitlenotecardflex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50vh;
  margin-right: 15em;
}

.skillsparagraphlistflex {
  display: flex;
  flex-direction: row;
}
.skillsparagraphinnerbox {
  background-color: white;
}

.skillslistbox {
  text-align: center;
  width: 30em;
  border-radius: 0.5em;
  border: solid 2px #f6d7ba;
  padding: 2em;
  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237b9eba' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.skillslistinnerbox {
  background-color: white;
  padding: 0.5em;
  padding-right: 0.5em;
}
.JavaScript {
  color: white;
  border-radius: 0.5em;
  background-color: #7b9eba;
  width: 8em;
  text-align: center;
}

.React {
  color: white;
  border-radius: 0.5em;
  background-color: #7b9eba;
  width: 8em;
  margin-top: 0.2em;
  text-align: center;
}

.Firebase {
  color: white;
  border-radius: 0.5em;
  background-color: #7b9eba;
  width: 8em;
  margin-top: 0.2em;
  text-align: center;
  width: 8em;
  margin-top: 0.2em;
  text-align: center;
}

.HTML {
  color: white;
  border-radius: 0.5em;
  background-color: #7b9eba;
  width: 8em;
  margin-top: 0.2em;
  text-align: center;
  width: 8em;
}

.Sass {
  color: white;
  border-radius: 0.5em;
  background-color: #7b9eba;
  width: 8em;
  margin-top: 0.2em;
  text-align: center;
  width: 8em;
}

.Node {
  color: white;
  border-radius: 0.5em;
  background-color: #7b9eba;
  width: 8em;
  margin-top: 0.2em;
  text-align: center;
  width: 8em;
}

.CSS {
  color: white;
  border-radius: 0.5em;
  background-color: #7b9eba;
  width: 8em;
  margin-top: 0.2em;
  text-align: center;
  width: 8em;
}

.Adobe {
  text-align: center;
  color: white;
  border-radius: 0.5em;
  width: 8em;
  text-align: center;
  margin-left: 1em;
  background-color: #7b9eba;
  border-radius: 0.5em;
}

.agile {
  color: white;
  border-radius: 0.5em;
  background-color: #7b9eba;
  width: 8em;
  margin-top: 0.2em;
  text-align: center;
  margin-left: 1em;
}

.testing {
  color: white;
  border-radius: 0.5em;
  background-color: #7b9eba;
  width: 8em;
  margin-top: 0.2em;
  text-align: center;
  margin-left: 1em;
}

.git {
  color: white;
  border-radius: 0.5em;
  background-color: #7b9eba;
  width: 8em;
  margin-top: 0.2em;
  text-align: center;
  margin-left: 1em;
}

.bash {
  color: white;
  border-radius: 0.5em;
  background-color: #7b9eba;
  width: 8em;
  margin-top: 0.2em;
  text-align: center;
  margin-left: 1em;
}

.OOP {
  color: white;
  border-radius: 0.5em;
  background-color: #7b9eba;
  width: 8em;
  margin-top: 0.2em;
  text-align: center;
  margin-left: 1em;
}

.APIs {
  color: white;
  border-radius: 0.5em;
  background-color: #7b9eba;
  width: 8em;
  margin-top: 0.2em;
  text-align: center;
  margin-left: 1em;
}

.vscode {
  color: white;
  border-radius: 0.5em;
  width: 8em;
  margin-top: 0.2em;
  text-align: center;
  margin-left: 1em;
  background-color: #9fcba0;
  border-radius: 0.5em;
}
.skillslistflex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  justify-content: space-between;
}

.skillslisttwoflex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.skillsparagraphflex {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 80vw;
  padding-right: 6em;
}

.skillssectionbox {
  background: url("../../croppedfinaljunglewhole gradient.svg") bottom no-repeat;
  background-size: width 100%;
  background-size: height 80%;
  background-color: #7b9eba;
  width: 80vw;
  height: 80vh;
  box-shadow: 0 1px 3px 0px #5c5959;
  border-radius: 1em;
  margin-top: 3em;
}

.navigationpin {
  height: 5em;
  width: 5em;
}

.pinflex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 30em;
}

.skillsparagraph {
  color: #fcbc80;
  text-align: center;
  width: 25em;
  border-radius: 0.5em;
  border: solid 2px #f6d7ba;
  border-radius: 0.5em;
  padding: 2em;
  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237b9eba' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

.skillsparagraph:hover {
  animation: swing ease-in-out 1s infinite alternate;
  transform-origin: center -20px;
  float: left;
}

.swing img {
  border: 5px solid #f8f8f8;
  display: block;
}

.swing:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  top: -10px;
  left: 50%;
  z-index: 0;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
}

.swing:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  top: -14px;
  left: 54%;
  z-index: 5;
  border-radius: 50% 50%;
  background: #000;
}

@keyframes swing {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-2deg);
  }
}

.skillslistbox:hover {
  animation: swing ease-in-out 1s infinite alternate;
  transform-origin: center -20px;
  float: left;
}

.swing img {
  border: 5px solid #f8f8f8;
  display: block;
}

.swing:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #999;
  top: -10px;
  left: 50%;
  z-index: 0;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
}
/* nail */
.swing:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  top: -14px;
  left: 54%;
  z-index: 5;
  border-radius: 50% 50%;
  background: #000;
}

@keyframes swing {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-2deg);
  }
}

.skillssectionboxflex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 7em;
  // padding-top: 10.5em;
}

.skillsparagraphlistflex {
  display: flex;
  flex-direction: column;
}

.skillsparagraphinnerbox {
  background-color: white;
  padding: 2em;
}

.flyingplane {
  width: 16em;
  height: 8em;
}

@media only screen and (min-width: 319px) and (max-width: 374px) {
  .skillslistbox {
    text-align: center;
    width: 15em;
    border-radius: 0.5em;
    border: solid 2px #f6d7ba;
    padding: 0.5em;
    background-color: white;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237b9eba' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  .skillssectionboxflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 0em;
  }

  .skillstitlenotecardflex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 50vh;
    margin-right: 5em;
  }
  .skillslistinnerbox {
    background-color: white;
    padding-right: 0em;
    padding-bottom: 0em;
  }
  .skillstitle {
    color: #f6d7ba;
    font-size: 2.8em;
    margin-left: 1em;
    margin-top: 1em;
    font-family: "Merriweather", serif;
  }

  .flyingplane {
    width: 8em;
    height: 5em;
    margin-top: 0em;
  }

  .skillssectionboxflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .skillslisttwo {
    margin-right: 0.2em;
  }
  .skillslist {
    margin-left: 0.2em;
  }
  .navigationpinflex {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15em;
  }
  .navigationpin {
    height: 2.5em;
    width: 2.5em;
  }

  .pinflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 15em;
  }
  .skillsparagraph {
    color: #fcbc80;
    text-align: center;
    width: 5em;
    border-radius: 0.5em;
    border: solid 2px #f6d7ba;
    border-radius: 0.5em;
    padding: 1em;
    background-color: white;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237b9eba' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }
  .cardflex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 5vw;
    padding-left: 0em;
  }

  .skillssectionbox {
    background: url("../../croppedfinaljunglewhole gradient.svg") bottom
      no-repeat;
    background-size: width 100%;
    background-size: height 80%;
    background-color: #7b9eba;
    width: 90vw;
    height: 80vh;
    box-shadow: 0 1px 3px 0px #5c5959;
    border-radius: 1em;
    margin-top: 8em;
  }

  .JavaScript {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    text-align: center;
    font-size: 1em;
  }

  .React {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    text-align: center;
  }

  .Firebase {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .HTML {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .Sass {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .Node {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .CSS {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .Adobe {
    text-align: center;
    color: white;
    border-radius: 0.5em;
    width: 6em;
    text-align: center;
    margin-left: 0.5em;
    background-color: #7b9eba;
    border-radius: 0.5em;
    margin-right: 2em;
  }

  .agile {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .testing {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .git {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .bash {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .OOP {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .APIs {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
    margin-bottom: 1em;
  }

  .skillsboxflex {
    width: 80%;
    // margin-top: 5em;
    padding-right: 5em;
    padding-left: 2em;
    padding-bottom: 1em;
  }
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  .skillslistbox {
    text-align: center;
    width: 15em;
    border-radius: 0.5em;
    border: solid 2px #f6d7ba;
    padding: 0.5em;
    background-color: white;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237b9eba' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

 
  .skillstitlenotecardflex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 50vh;
    margin-right: 5em;
  }
  .skillslistinnerbox {
    background-color: white;
    padding-right: 0em;
    padding-bottom: 0em;
  }
  .skillstitle {
    color: #f6d7ba;
    font-size: 2.8em;
    margin-left: 1em;
    margin-top: 1em;
    font-family: "Merriweather", serif;
  }

  .flyingplane {
    width: 8em;
    height: 5em;
    margin-top: 0em;
  }

  .skillssectionboxflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .skillslisttwo {
    margin-right: 0.2em;
  }
  .skillslist {
    margin-left: 0.2em;
  }
  .navigationpinflex {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15em;
  }
  .navigationpin {
    height: 2.5em;
    width: 2.5em;
  }

  .pinflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 15em;
  }
  .skillsparagraph {
    color: #fcbc80;
    text-align: center;
    width: 5em;
    border-radius: 0.5em;
    border: solid 2px #f6d7ba;
    border-radius: 0.5em;
    padding: 1em;
    background-color: white;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237b9eba' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }
  .cardflex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 5vw;
    padding-left: 0em;
  }

  .skillssectionbox {
    background: url("../../croppedfinaljunglewhole gradient.svg") bottom
      no-repeat;
    background-size: width 100%;
    background-size: height 80%;
    background-color: #7b9eba;
    width: 90vw;
    height: 80vh;
    box-shadow: 0 1px 3px 0px #5c5959;
    border-radius: 1em;
    margin-top: 8em;
  }

  .JavaScript {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    text-align: center;
    font-size: 1em;
  }

  .React {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    text-align: center;
  }

  .Firebase {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .HTML {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .Sass {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .Node {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .CSS {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .Adobe {
    text-align: center;
    color: white;
    border-radius: 0.5em;
    width: 6em;
    text-align: center;
    margin-left: 0.5em;
    background-color: #7b9eba;
    border-radius: 0.5em;
    margin-right: 2em;
  }

  .agile {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .testing {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .git {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .bash {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .OOP {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .APIs {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
    margin-bottom: 1em;
  }

  .skillsboxflex {
    width: 80%;
    // margin-top: 5em;
    padding-right: 5em;
    padding-left: 3em;
    padding-bottom: 1em;
  }
}
@media only screen and (min-width: 426px) and (max-width: 480px) {
  .skillslistbox {
    text-align: center;
    width: 15em;
    border-radius: 0.5em;
    border: solid 2px #f6d7ba;
    padding: 0.5em;
    background-color: white;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237b9eba' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  .skillssectionboxflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 0em;
  }

  .skillstitlenotecardflex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 50vh;
    margin-right: 5em;
  }
  .skillslistinnerbox {
    background-color: white;
    padding-right: 0em;
    padding-bottom: 0em;
  }
  .skillstitle {
    color: #f6d7ba;
    font-size: 2.8em;
    margin-left: 1em;
    margin-top: 1em;
    font-family: "Merriweather", serif;
  }

  .flyingplane {
    width: 10em;
    height: 5em;
    margin-top: 6em;
  }

  .skillssectionboxflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .skillslisttwo {
    margin-right: 0.2em;
  }
  .skillslist {
    margin-left: 0.2em;
  }
  .navigationpinflex {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15em;
  }
  .navigationpin {
    height: 2.5em;
    width: 2.5em;
  }

  .pinflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 15em;
  }
  .skillsparagraph {
    color: #fcbc80;
    text-align: center;
    width: 5em;
    border-radius: 0.5em;
    border: solid 2px #f6d7ba;
    border-radius: 0.5em;
    padding: 1em;
    background-color: white;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237b9eba' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }
  .cardflex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 5vw;
    padding-left: 0em;
  }

  .skillssectionbox {
    background: url("../../croppedfinaljunglewhole gradient.svg") bottom
      no-repeat;
    background-size: width 100%;
    background-size: height 80%;
    background-color: #7b9eba;
    width: 90vw;
    height: 80vh;
    box-shadow: 0 1px 3px 0px #5c5959;
    border-radius: 1em;
    margin-top: 8em;
  }

  .JavaScript {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    text-align: center;
    font-size: 1em;
  }

  .React {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    text-align: center;
  }

  .Firebase {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .HTML {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .Sass {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .Node {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .CSS {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .Adobe {
    text-align: center;
    color: white;
    border-radius: 0.5em;
    width: 6em;
    text-align: center;
    margin-left: 0.5em;
    background-color: #7b9eba;
    border-radius: 0.5em;
    margin-right: 2em;
  }

  .agile {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .testing {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .git {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .bash {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .OOP {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .APIs {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
    margin-bottom: 1em;
  }

  .skillsboxflex {
    width: 80%;
    // margin-top: 5em;
    padding-right: 5em;
    padding-left: 7em;
    padding-bottom: 1em;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .skillslistbox {
    text-align: center;
    width: 20em;
    border-radius: 0.5em;
    border: solid 2px #f6d7ba;
    padding: 1em;
    background-color: white;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237b9eba' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  .skillssectionboxflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 0em;
  }

  .skillstitlenotecardflex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 50vh;
    margin-right: 5em;
  }
  .skillslistinnerbox {
    background-color: white;
    padding-right: 0em;
    padding-bottom: 0em;
  }
  .skillstitle {
    color: #f6d7ba;
    font-size: 2.8em;
    margin-left: 1em;
    margin-top: 1em;
    font-family: "Merriweather", serif;
  }

  .flyingplane {
    width: 16em;
    height: 8em;
    // padding-top: 7em;
  }

  .skillssectionboxflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .skillslisttwo {
    margin-right: 0em;
  }
  .skillslist {
    margin-left: 1em;
  }
  .navigationpinflex {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 15em;
  }
  .navigationpin {
    height: 3em;
    width: 3em;
  }

  .pinflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 20em;
  }
  .skillsparagraph {
    color: #fcbc80;
    text-align: center;
    width: 5em;
    border-radius: 0.5em;
    border: solid 2px #f6d7ba;
    border-radius: 0.5em;
    padding: 1em;
    background-color: white;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237b9eba' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }
  .cardflex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 5vw;
    padding-left: 0em;
  }

  .skillssectionbox {
    background: url("../../croppedfinaljunglewhole gradient.svg") bottom
      no-repeat;
    background-size: width 100%;
    background-size: height 80%;
    background-color: #7b9eba;
    width: 90vw;
    height: 80vh;
    box-shadow: 0 1px 3px 0px #5c5959;
    border-radius: 1em;
    margin-top: 8em;
  }

  .JavaScript {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    text-align: center;
    font-size: 1em;
  }

  .React {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    text-align: center;
  }

  .Firebase {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .HTML {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .Sass {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .Node {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .CSS {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
  }

  .Adobe {
    text-align: center;
    color: white;
    border-radius: 0.5em;
    width: 6em;
    text-align: center;
    margin-left: 0.5em;
    background-color: #7b9eba;
    border-radius: 0.5em;
    margin-right: 2em;
  }

  .agile {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .testing {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .git {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .bash {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .OOP {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
  }

  .APIs {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 6em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 0.5em;
    margin-bottom: 1em;
  }

  .skillsboxflex {
    width: 80%;
    padding-right: 5em;
    padding-left: 15em;
    padding-bottom: 1em;
  }

  .imageflex {
    height: 30em;
    padding-top: 5em;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .skillstitle {
    color: #f6d7ba;
    font-size: 3.5em;
    margin-left: 1em;
    margin-top: 1em;
    font-family: "Merriweather", serif;
  }
  .skillslisttwo {
    margin-right: 1em;
  }
  .skillslist {
    margin-left: 0.5em;
  }

  .navigationpinflex {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 23em;
  }

  .skillsboxflexrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80vw;
    height: 80vh;
    margin-top: 4em;
  }
  .skillsboxflex {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .skillstitlenotecardflex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50vh;
    margin-right: 15em;
  }

  .skillsparagraphlistflex {
    display: flex;
    flex-direction: row;
  }
  .skillsparagraphinnerbox {
    background-color: white;
  }

  .skillslistbox {
    text-align: center;
    width: 23em;
    border-radius: 0.5em;
    border: solid 2px #f6d7ba;
    padding: 2em;
    background-color: white;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237b9eba' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }

  .skillslistinnerbox {
    background-color: white;
    padding: 0.5em;
    padding-right: 0.5em;
  }
  .JavaScript {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 8em;
    text-align: center;
  }

  .React {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 8em;
    margin-top: 0.2em;
    text-align: center;
  }

  .Firebase {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 8em;
    margin-top: 0.2em;
    text-align: center;
  }

  .HTML {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 8em;
    margin-top: 0.2em;
    text-align: center;
    width: 8em;
  }

  .Sass {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 8em;
    margin-top: 0.2em;
    text-align: center;
    width: 8em;
  }

  .Node {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 8em;
    margin-top: 0.2em;
    text-align: center;
    width: 8em;
  }

  .CSS {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 8em;
    margin-top: 0.2em;
    text-align: center;
    width: 8em;
  }

  .Adobe {
    text-align: center;
    color: white;
    border-radius: 0.5em;
    width: 8em;
    text-align: center;
    margin-left: 1em;
    background-color: #7b9eba;
    border-radius: 0.5em;
  }

  .agile {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 8em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 1em;
  }

  .testing {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 8em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 1em;
  }

  .git {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 8em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 1em;
  }

  .bash {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 8em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 1em;
  }

  .OOP {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 8em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 1em;
  }

  .APIs {
    color: white;
    border-radius: 0.5em;
    background-color: #7b9eba;
    width: 8em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 1em;
  }

  .vscode {
    color: white;
    border-radius: 0.5em;
    width: 8em;
    margin-top: 0.2em;
    text-align: center;
    margin-left: 1em;
    background-color: #9fcba0;
    border-radius: 0.5em;
  }
  .skillslistflex {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    justify-content: space-between;
  }

  .skillslisttwoflex {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .skillsparagraphflex {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 80vw;
    padding-right: 6em;
  }

  .skillssectionbox {
    background: url("../../croppedfinaljunglewhole gradient.svg") bottom
      no-repeat;
    background-size: width 100%;
    background-size: height 80%;
    background-color: #7b9eba;
    width: 90vw;
    height: 80vh;
    box-shadow: 0 1px 3px 0px #5c5959;
    border-radius: 1em;
    margin-top: 3em;
  }

  .navigationpin {
    height: 4em;
    width: 4em;
  }

  .pinflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 25em;
  }

  .skillsparagraph {
    color: #fcbc80;
    text-align: center;
    width: 25em;
    border-radius: 0.5em;
    border: solid 2px #f6d7ba;
    border-radius: 0.5em;
    padding: 2em;
    background-color: white;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237b9eba' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  }

  .skillsparagraph:hover {
    animation: swing ease-in-out 1s infinite alternate;
    transform-origin: center -20px;
    float: left;
  }

  .swing img {
    border: 5px solid #f8f8f8;
    display: block;
  }

  .swing:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    top: -10px;
    left: 50%;
    z-index: 0;
    border-bottom: none;
    border-right: none;
    transform: rotate(45deg);
  }

  .swing:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    top: -14px;
    left: 54%;
    z-index: 5;
    border-radius: 50% 50%;
    background: #000;
  }

  @keyframes swing {
    0% {
      transform: rotate(3deg);
    }
    100% {
      transform: rotate(-2deg);
    }
  }

  .skillslistbox:hover {
    animation: swing ease-in-out 1s infinite alternate;
    transform-origin: center -20px;
    float: left;
  }

  .swing img {
    border: 5px solid #f8f8f8;
    display: block;
  }

  .swing:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    top: -10px;
    left: 50%;
    z-index: 0;
    border-bottom: none;
    border-right: none;
    transform: rotate(45deg);
  }
  /* nail */
  .swing:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    top: -14px;
    left: 54%;
    z-index: 5;
    border-radius: 50% 50%;
    background: #000;
  }

  @keyframes swing {
    0% {
      transform: rotate(3deg);
    }
    100% {
      transform: rotate(-2deg);
    }
  }

  .skillssectionboxflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 7em;
    // padding-top: 10.5em;
  }

  .skillsparagraphlistflex {
    display: flex;
    flex-direction: column;
  }

  .skillsparagraphinnerbox {
    background-color: white;
    padding: 2em;
  }

  .flyingplane {
    width: 15em;
    height: 15em;
    padding-top: 8em;
  }
  .imageflex {
    height: 20vh;
  }
}
