.navsection {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 6.5em;
  box-shadow: 0 2px 6px -5px #000000;
  background-color: white;
  // animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  z-index: 999;
  align-items: center;
}

@import url("https://fonts.googleapis.com/css2?family=Creepster&family=Open+Sans&family=PT+Serif:wght@700&display=swap");

.navflex {
  display: flex;
  flex-direction: row;
  margin-left: 10em;
  padding-bottom: 1em;
  justify-content: space-between;
  position: relative;
  margin-top: 1em;
  padding-top: 1em;
}

.bridget a {
  color: #9fcba0;
  font-size: 1.7em;
  text-decoration: none;
  margin-left: 1em;
}

.bridget a:hover {
  border: solid 2px #9fcba0;
}
.aboutme a {
  color: #7b9eba;

  font-size: 1.7em;
}

.aboutme a:hover {
  border: solid 2px #7b9eba;
}

.projects a {
  color: #9fcba0;
  font-size: 1.7em;
}

.projects a:hover {
  border: solid 2px #9fcba0;
}

.skills a {
  color: #f6d7ba;
  font-size: 1.7em;
}

.skills a:hover {
  border: solid 2px #f6d7ba;
}

.sayhello a {
  color: #f7d544;
  font-size: 1.7em;
}

.sayhello a:hover {
  border: solid 2px #f7d544;
}

.navItemsFlex {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-right: 11em;
}

.navItemsFlexExpanded {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-right: 11em;
  // font-size: 1.2em;
  // padding-top: 3em;
}

.menuWidget {
  border: 0;
  border-radius: 50%;
  background-color: white;
  color: #7b9eba;
  font-size: 3em;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

@media only screen and (min-width: 319px) and (max-width: 374px) {
  .menuWidget {
    display: block;
    margin-right: 0em;
  }

  .bridget {
    margin-left: 1em;
    margin-top: 1.5em;
    font-size: 1em;
  }
  .bridget a {
    color: #9fcba0;
    font-size: 1.2em;
    text-decoration: none;
    margin-left: 0em;
  }

  .navflex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 100%;
    margin-left: 0em;
  }

  .navItemsFlex ul {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    min-width: 100%;
    height: calc(100vh - 60px);
    background-color: white;
    border-top: 1px solid #7b9eba;
    display: none;
    padding-top: 3em;
  }

  .navItemsFlexExpanded {
    flex-direction: column;
    position: absolute;
    top: 128px;
    left: 0;
    min-width: 100%;
    height: 100vh;
    background-color: white;
    border-top: 1px solid #7b9eba;
    display: block;
    text-align: center;
    margin: 0;
  }
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  .menuWidget {
    display: block;
    margin-right: 0.1em;
  }

  .bridget {
    margin-left: 1.3em;
    margin-top: 1.5em;
    font-size: 1em;
  }
  .bridget a {
    color: #9fcba0;
    font-size: 1.2em;
    text-decoration: none;
    margin-left: 0em;
  }
  .navflex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 100%;
    margin-left: 0em;
  }
  .navItemsFlex ul {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    min-width: 100%;
    height: calc(100vh - 60px);
    background-color: white;
    border-top: 1px solid #7b9eba;
    display: none;
    padding-top: 20px;
  }

  .navItemsFlexExpanded {
    flex-direction: column;
    position: absolute;
    top: 128px;
    left: 0;
    min-width: 100%;
    height: 100vh;
    // height:100%;
    background-color: white;
    border-top: 1px solid #7b9eba;
    display: block;
    text-align: center;
    margin: 0;
  }

  .navsection {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 6.5em;
    box-shadow: 0 2px 6px -5px #000000;
    background-color: white;
    z-index: 100;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 426px) and (max-width: 480px) {
  .menuWidget {
    display: block;
    margin-right: 0.1em;
  }

  .bridget {
    margin-left: 0.1em;
    margin-top: 0.8em;
  }

  .navflex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 100%;
    margin-left: 0em;
  }

  .navItemsFlex ul {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    min-width: 100%;
    height: calc(100vh - 60px);
    background-color: white;
    border-top: 1px solid #7b9eba;
    display: none;
    padding-top: 20px;
  }

  .navItemsFlexExpanded {
    flex-direction: column;
    position: absolute;
    top: 128px;
    left: 0;
    min-width: 100%;
    height: 100vh;
    // height:100%;
    background-color: white;
    border-top: 1px solid #7b9eba;
    display: block;
    text-align: center;
    margin: 0;
  }

  .navsection {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 6.5em;
    box-shadow: 0 2px 6px -5px #000000;
    background-color: white;
    z-index: 100;
    align-items: center;
    width: 100%;
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .menuWidget {
    display: block;
    margin-right: 0.5em;
  }

  .bridget {
    margin-left: 0.8em;
    margin-top: 1em;
  }

  .navflex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 100%;
    margin-left: 0em;
  }

  .navItemsFlex ul {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    min-width: 100%;
    height: calc(100vh - 60px);
    background-color: white;
    border-top: 1px solid #7b9eba;
    display: none;
    padding-top: 20px;
  }

  .navItemsFlexExpanded {
    flex-direction: column;
    position: absolute;
    top: 128px;
    left: 0;
    min-width: 100%;
    height: 100vh;
    // height:100%;
    background-color: white;
    border-top: 1px solid #7b9eba;
    display: block;
    text-align: center;
    margin: 0;
    font-size: 1.5em;
    padding-top: 2em;
  }
  .navsection {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 6.5em;
    box-shadow: 0 2px 6px -5px #000000;
    background-color: white;
    z-index: 100;
    align-items: center;
    width: 100%;
  }
}
