.projectspage {
  min-height: 100vh;
  background-color: white;
  width: 100vw;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%237b9eba' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

.projectsFlex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // padding-top: 10.5em;
}

.projectslinktop {
  color: white;
}

.projectstitle {
  color: #f6d7ba;
  font-size: 4em;
  margin-left: 2em;
  margin-top: 1em;
  font-family: "Merriweather", serif;
  width: 10em;
  margin-left: 2em;
}

.projecttitleflex {
  background-color: #7b9eba;
  border-radius: 1em;
  width: 80vw;
  height: 80vh;
  box-shadow: 0 1px 3px 0px #5c5959;
  margin-top: 10.5em;
  position: relative;
}
.navigationpin {
  height: 5em;
  width: 5em;
}

@media only screen and (min-width: 319px) and (max-width: 374px) {
  .projecttitleflex {
    margin-top: 8em;
    width: 90vw;
    height: 80vh;
  }
  .projectstitle {
    color: #f6d7ba;
    font-size: 2.8em;
    margin-left: 1em;
    margin-top: 1em;
    font-family: "Merriweather", serif;
  }
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  .projecttitleflex {
    margin-top: 8em;
    width: 90vw;
    height: 80vh;
  }
  .projectstitle {
    color: #f6d7ba;
    font-size: 2.8em;
    margin-left: 1em;
    margin-top: 1em;
    font-family: "Merriweather", serif;
  }
}

@media only screen and (min-width: 426px) and (max-width: 480px) {
  .projecttitleflex {
    margin-top: 8em;
    width: 90vw;
    height: 80vh;
  }
  .projectstitle {
    color: #f6d7ba;
    font-size: 2.8em;
    margin-left: 1em;
    margin-top: 1em;
    font-family: "Merriweather", serif;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .projecttitleflex {
    margin-top: 8em;
    width: 90vw;
    height: 80vh;
  }
  .projectstitle {
    color: #f6d7ba;
    font-size: 2.8em;
    margin-left: 1em;
    margin-top: 1em;
    font-family: "Merriweather", serif;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .projecttitleflex {
    margin-top: 8em;
    width: 90vw;
    height: 80vh;
  }
  .projectstitle {
    color: #f6d7ba;
    font-size: 3.5em;
    margin-left: 1em;
    margin-top: 1em;
    font-family: "Merriweather", serif;
  }
}
