.Projectlabel {
  color: white;
  background-color: white;
  width: 15em;
  height: 15em;
  border-radius: 10px;
  box-shadow: 0 4px 7px rgba(80, 174, 245, 0.2), 0 4px 7px rgba(0, 0, 0, 0.27);
}

.imagepath {
  width: 15em;
  height: 15em;
  border-radius: 10px;
}


@media only screen and (min-width: 319px) and (max-width: 374px) {
  .Projectlabel {
    color: white;
    background-color: white;
    width: 6em;
    height: 6em;
    border-radius: 10px;
    box-shadow: 0 4px 7px rgba(80, 174, 245, 0.2), 0 4px 7px rgba(0, 0, 0, 0.27);
  }
  .imagepath {
    width: 6em;
    height: 6em;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
  .Projectlabel {
        color: white;
        background-color: white;
        width: 8em;
        height: 8em;
        border-radius: 10px;
        box-shadow: 0 4px 7px rgba(80, 174, 245, 0.2), 0 4px 7px rgba(0, 0, 0, 0.27);
      }
      .imagepath {
        width: 8em;
        height: 8em;
        border-radius: 10px;
      }
}

@media only screen and (min-width: 426px) and (max-width: 480px) {
  .Projectlabel {
    color: white;
    background-color: white;
    width: 9em;
    height: 9em;
    border-radius: 10px;
    box-shadow: 0 4px 7px rgba(80, 174, 245, 0.2), 0 4px 7px rgba(0, 0, 0, 0.27);
  }
  .imagepath {
    width: 9em;
    height: 9em;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .Projectlabel {
    color: white;
    background-color: white;
    width: 10em;
    height: 10em;
    border-radius: 10px;
    box-shadow: 0 4px 7px rgba(80, 174, 245, 0.2), 0 4px 7px rgba(0, 0, 0, 0.27);
  }
  .imagepath {
    width: 10em;
    height: 10em;
    border-radius: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .Projectlabel {
    color: white;
    background-color: white;
    width: 11em;
    height: 11em;
    border-radius: 10px;
    box-shadow: 0 4px 7px rgba(80, 174, 245, 0.2), 0 4px 7px rgba(0, 0, 0, 0.27);
  }
  .imagepath {
    width: 11em;
    height: 11em;
    border-radius: 10px;
  }
}
